<template>
  <el-dialog
    title="修改"
    :visible.sync="show"
    width="500px"
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div></div>
    <div slot="footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      show: false,
    };
  },
  methods: {
    beforeClose(done) {
      done();
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
